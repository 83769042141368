import { FaAnchor, FaArchive, FaChartLine, FaCloud, FaCloudDownloadAlt, FaCode, FaCss3, FaDatabase, FaFighterJet, FaFileImage, FaHtml5, FaLink, FaPowerOff, FaThumbsUp, FaUniversity } from 'react-icons/fa'
import { Link, graphql } from 'gatsby'

import Box from '../components/sections/Box'
import Button from '@material-ui/core/Button'
import Column from '../components/section-components/Column'
import Columns from '../components/sections/Columns'
import Grid from '@material-ui/core/Grid'
import Head from '../components/informational/Head'
import Hero from '../components/sections/Hero'
import Logo from '../components/informational/Logo'
import MaterialLink from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import React from 'react'
import ShareWidget from '../components/informational/ShareWidget'
import StartDownloadForm from '../components/controls/StartDownloadForm'
import Tweet from '../components/informational/Tweet'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

export const query = graphql`
  query {
    site {
      siteMetadata {
        tweets {
          id
          handle
          name
          verified
          body
          time
          likes
        }
      }
    }
  }
`

const styles = theme => ({
  /* Ugly fix for misplaced DownloadForm label */
  '@global': {
    '@media only screen and (min-width: 960px)': {
      'form > div > div > fieldset > legend': {
        width: '70% !important',
        marginLeft: '-30%'
      }
    }
  },
  fullWidth: theme.mixins.fullWidth,
  seoStrong: theme.mixins.seoStrong
})

const Component = ({ classes, data }) => (
  <>
    <Head title='Website Downloader | Website Copier | Site Downloader | Website Ripper' frontPage />
    <ShareWidget />

    <Hero icon={<Logo frontPage />} title='Website Downloader' subTitle='Download all the source code and assets of any website' frontPage>
      <StartDownloadForm index={0} autoFocus />
    </Hero>
    <Columns title='Key Benefits' subTitle={<>Easiest way to <strong className={classes.seoStrong}>download a website</strong>.</>} perrow={3}>
      <Column icon={<FaCloudDownloadAlt />} title='Platform Independent'>
        The web based interface enables you to use <strong className={classes.seoStrong}>website ripper</strong> straight in your browser on any operating system and without downloading or configuring any software. Use it when you need to quickly <strong className={classes.seoStrong}>download website</strong>.
      </Column>
      <Column icon={<FaFighterJet />} title='Fast Previews'>
        Website Downloader offers a fast preview of the download result hosted on our servers without consuming your computer's precious disk space.  After the preview you can <strong className={classes.seoStrong}>download web page</strong> or <strong className={classes.seoStrong}>download entire website</strong>.
      </Column>
      <Column icon={<FaThumbsUp />} title='Simplicity'>
        Website Downloader is super simple and easy to use, yet it comes with advanced functionality such as only downloading a subdirectory or certain pages from a website (as a <strong className={classes.seoStrong}>web page downloader</strong>).  <strong className={classes.seoStrong}>Website grabber</strong> is the easiest way to <strong className={classes.seoStrong}>download a website</strong>.
      </Column>
    </Columns>
    <Columns title='What Are People Saying About Us' subTitle={<>Experiences about the ultimate <strong className={classes.seoStrong}>Website Copier</strong>.</>} background='backgroundSecondary'>
      {data.site.siteMetadata.tweets.map((tweet, index) => {
        const avatar = require(`../images/tweets/${tweet.handle}.jpg`)

        return (
          <Grid item align='center' className={classes.fullWidth} sm={8} md={4} lg={3} key={index}>
            <Tweet name={tweet.name} handle={tweet.handle} time={tweet.time} likes={tweet.likes} id={tweet.id} avatar={avatar} verified={tweet.verified}>
              {tweet.body}
            </Tweet>
          </Grid>
        )
      })}
      <Grid item align='center' sm={8}>
        <Typography variant='h4' align='center' color='textSecondary' gutterBottom>
          Like Website Downloader too? Vote for us on <MaterialLink href='https://rankedbyvotes.com/web-development/website-downloaders/' color='textSecondary' title='Best Website Downloaders' target='_blank' rel='noopener'>RankedByVotes</MaterialLink>
        </Typography>
      </Grid>
    </Columns>
    <Columns title='Website Downloader Features' subTitle={<>Website Downloader, <strong className={classes.seoStrong}>Website Copier</strong> or <strong className={classes.seoStrong}>Website Ripper</strong> allows you to download websites from the Internet to your local hard drive on your own computer. Website Downloader arranges the downloaded site by the original websites relative link-structure. The downloaded website can be browsed by opening one of the HTML pages in a browser.</>} perrow={4}>
      <Column icon={<FaHtml5 />} title='HTML'>
        The <strong className={classes.seoStrong}>web grabber</strong> takes each HTML file and downloads and clones it to your local hard drive. You can use a <MaterialLink href='https://htmleditor.io/' color='textSecondary' title='Online HTML Editor' target='_blank' rel='noopener'>HTML editor</MaterialLink> to make changes to each html file locally or use a <MaterialLink href='https://htmleditor.io/' title='Online HTML Editor' color='textSecondary' target='_blank' rel='noopener'>HTML editor online</MaterialLink>. In case you are not a coder you can use a <MaterialLink href='https://htmleditor.io/' title='Online HTML Editor' color='textSecondary' target='_blank' rel='noopener'>WYSIWYG-HTML Editor</MaterialLink> instead.
      </Column>
      <Column icon={<FaLink />} title='Link Conversion'>
        We convert all the links in the HTML files so that they work locally, off-line, instead of pointing to an online website.
      </Column>
      <Column icon={<FaCss3 />} title='CSS &amp; JavaScript'>
        CSS and JavaScript files will be downloaded under a simple folder structure and referenced correctly in the HTML files.
      </Column>
      <Column icon={<FaFileImage />} title='Images'>
        Like other assets, also images will downloaded and referenced locally.
      </Column>
    </Columns>
    <Columns title='Popular Website Downloader Use Cases' subTitle={<>After cloning a website to your hard drive you can open the websites source code with a code editor or simply browse it offline using a browser of your choosing. <strong className={classes.seoStrong}>Site Downloader</strong> can be used for multiple different purposes. It's a truly simple to use <strong className={classes.seoStrong}>website download software</strong> without downloading anything.</>} perrow={2} iconColor='textPrimary'>
      <Column icon={<FaArchive />} title='Backups'>
        If you have a website, you should always have a recent backup of the website in case the server breaks or you get hacked. Website Downloader is the fastest and easiest option to take a backup of your website, it allows you to <strong className={classes.seoStrong}>download whole website</strong>.
      </Column>
      <Column icon={<FaPowerOff />} title='Offline Website Downloader'>
        <strong className={classes.seoStrong}>Download website offline</strong> for your future reference, which you can access even without an internet connection, say. when you are on a flight or an island vacation!
      </Column>
      <Column icon={<FaCloud />} title='Site Migration'>
        If you're locked in to your hosting provider or for some other reason don't have access to your website's source files, simply use <strong className={classes.seoStrong}>website ripper copier</strong> to download the files and migrate your website to a new server. Remember to set correct file permissions with <MaterialLink href='https://chmodcommand.com/' title='Chmod Command Calculator' target='_blank' rel='noopener' color='textSecondary'>chmod calculator</MaterialLink> when migrating.
      </Column>
      <Column icon={<FaCode />} title='Learning'>
        If you're a web designer or a developer, take you skill set to the next level by reverse engineering website source code by <strong className={classes.seoStrong}>download complete website</strong> and learn new UX patterns and coding best practices. <strong className={classes.seoStrong}>Download full website</strong> to start learning.
      </Column>
      <Column icon={<FaDatabase />} title='Screen Scraping'>
        <strong className={classes.seoStrong}>Website downloader online</strong> allows you to extract useful data, information and knowledge from the contents of the web page. By running your scraping algorithms locally they run faster and smoother!
      </Column>
      <Column icon={<FaUniversity />} title='Internet Archive'>
        Forgot to pay for hosting renewal? Do not worry, your website is not lost. You can revive your websites from the Internet Archive using <MaterialLink component={Link} to='/wayback-machine-downloader/' title='Wayback Machine Downloader'>Wayback Machine Downloader</MaterialLink>
      </Column>
      <Column icon={<FaChartLine />} title='Machine Learning'>
        Web Content Mining: You can fetch multiple websites and run text analysis or machine learning algorithms on local data instead of having to fetch a new web page every single time, for new data.
      </Column>
      <Column icon={<FaAnchor />} title='Blogging Network'>
        Internet marketers can use <MaterialLink component={Link} to='/wayback-machine-downloader/' title='Wayback Machine Downloader'>Wayback Machine Downloader</MaterialLink> to create Blogging Networks from expired domains, without having to pay a single penny for content by downloading websites from the Internet Archive!
      </Column>
    </Columns>
    <Box title='Ready to Start Using Website Downloader?'>
      <StartDownloadForm index={1} />
      <Typography variant='body1' align='center' className={classes.subSection} gutterBottom>
        <Button color='primary' component={Link} to='/wayback-machine-downloader/' title='Wayback Machine Downloader'>Use Wayback Machine Downloader</Button> | <Button color='primary' component={Link} to='/blog/' title='The Website Downloader Blog'>Read our Blog</Button> | <Button color='primary' component={Link} to='/privacy/' title='Privacy Policy'>Privacy</Button> | <Button color='primary' component={Link} to='/terms/' title='Terms of Service'>Terms</Button> | <Button color='primary' component={Link} to='/cookies/' title='Cookie Policy'>Cookies</Button>
      </Typography>
    </Box>
  </>
)

export default withStyles(styles)(Component)

Component.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        tweets: PropTypes.array.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
}
